import React, { useState } from 'react';
// import QRCode from 'react-qr-code';
// import Modal from 'react-modal';
// import ReactTooltip from 'react-tooltip';

// import mainBg from './img/main-bg.jpg';
// import mainBg2x from './img/main-bg@2x.jpg';
// import mainBg3x from './img/main-bg@3x.jpg';

import instagramIcon from './img/instagram-icon.png';
import instagramIcon2x from './img/instagram-icon@2x.png';
import instagramIcon3x from './img/instagram-icon@3x.png';

import githubIcon from './img/github-icon.png';
import githubIcon2x from './img/github-icon@2x.png';

import linkedInIcon from './img/linked-in-icon.png';
import linkedInIcon2x from './img/linked-in-icon@2x.png';

// import btcLogo from './img/btc.svg';
// import ethLogo from './img/eth.svg';
// import bnbLogo from './img/bnb.svg';
// import adaLogo from './img/ada.svg';
// import dogeLogo from './img/doge.svg';
// import ltcLogo from './img/ltc.svg';
// import xrpLogo from './img/xrp.svg';
// import venmoLogo from './img/venmo.png';
// import cashLogo from './img/cashapp.svg';

import './App.scss';

const encodedEmail = 'YW1icm9zZUBrcmFwYWNzLmNvbQ==';
const getEmail = () => {
  return atob(encodedEmail);
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const PaymentModal = (props) => {
  const {
    address,
    memo,
    title,
    onRequestClose,
    showAddress,
    visible,
  } = props;

  return (
    <Modal
      ariaHideApp={false}
      isOpen={visible}
      style={customStyles}
      onRequestClose={onRequestClose}
      contentLabel="Example Modal"
    >
      <div className="ModalContent">
        <h1>{title}</h1>
        <QRCode value={address} />
        { showAddress ? (<p>{address}</p>) : null }
        { memo ? (<p>{memo}</p>) : null }
      </div>
    </Modal>
  );
};

export const App = () => {
    const [showEmail, setShowEmail] = useState(false);

    return (
      <div className="App">
        <div className="App-Content">
          
          <div className="App-HeaderText">
            Hi, I’m Ambrose. I explore. I take risks. I code.
          </div>

          <div className="App-SubHeader">
            <span>skier</span>
            <span className="App-SubHeaderSeparator">&bull;</span>
            <span>cyclist</span>
            <span className="App-SubHeaderSeparator">&bull;</span>
            <span>adventurer</span>
            <span className="App-SubHeaderSeparator">&bull;</span>
            <span>computer scientist</span>
          </div>

          <div className="App-GetInTouch">
            <p>Get in touch</p>
            <div className="App-Rectangle" />
          </div>

          <div className="App-AboutMe">
            <p>
                When I'm not writing code and building mobile apps or
                cloud applications I'm somewhere in the mountains —
                maybe finding the right line on my mountain bike,
                searching for untouched powder on my skis, or waking up
                to a view of the sunrise from my tent in the backcountry.
                <br/><br/>I like to build things big and small.
                <br/><br/>What are you dreaming up? Let me know.
            </p>
          </div>

          <div className="App-Me">
            <p>Ambrose Krapacs</p>
          </div>

          <div className="App-Contact">
            <div>
                { showEmail ?
                  (
                    <a className="link" href={`mailto:${getEmail()}`}>
                      {getEmail()}
                    </a>
                  ) : (
                    <span className="link" onClick={() => setShowEmail(true) }>Show Email</span>
                  )
                }
            </div>
          </div>

          <div className="App-Icons">
            <div className="App-SocialIcons">
              <a href="https://github.com/akrapacs">
                <img
                  className="App-Icon"
                  src={githubIcon}
                  srcSet={`${githubIcon2x} 2x`}
                  alt="GitHub Icon"
                />
              </a>
              <a href="https://www.linkedin.com/in/ambrosekrapacs/">
                <img
                  className="App-Icon"
                  src={linkedInIcon}
                  srcSet={`${linkedInIcon2x} 2x`}
                  alt="LinkedIn Icon"
                />
              </a>
              <a href="https://www.instagram.com/akrapacs">
                <img
                  className="App-Icon"
                  src={instagramIcon}
                  srcSet={`${instagramIcon2x} 2x, ${instagramIcon3x} 3x`}
                  alt="Instagram Icon"
                />
              </a>
            </div>
          </div>

          <div className="App-FindMe">
            <p>Find me on Github, LinkedIn or check out my site <a href="http://slidingx.com">slidingx.com</a></p>
          </div>
        </div>
      </div>
    );
};
